import React, { useState } from "react"
import styled from "styled-components"
import DisclosureFooter from "./DisclosureFooter"
import { NavigationBar } from "./NavigationBar"
import Dropdown from "./Dropdown"
import { Container, Row } from "react-bootstrap"

import { BsFillQuestionOctagonFill } from "@react-icons/all-files/bs/BsFillQuestionOctagonFill"
import { FaPeace } from "@react-icons/all-files/fa/FaPeace"
import { FcCalendar } from "@react-icons/all-files/fc/FcCalendar"
import { ImSad2 } from "@react-icons/all-files/im/ImSad2"
import { GiSkullCrossedBones } from "@react-icons/all-files/gi/GiSkullCrossedBones"
import { BiConversation } from "@react-icons/all-files/bi/BiConversation"

import SocialFooter from "./SocialFooter"
import CaseStudyCTA from "./CaseStudyCTA"
import Image1 from "../../assets/images/about/about-1.jpeg"
import Image2 from "../../assets/images/about/about-2.png"
import Image3 from "../../assets/images/about/about-3.png"
import Image4 from "../../assets/images/about/about-4.png"
import Image5 from "../../assets/images/about/about-5.png"
import Image6 from "../../assets/images/about/about-6.png"
import Image7 from "../../assets/images/about/about-7.png"
import Image8 from "../../assets/images/about/about-8.png"
import Image9 from "../../assets/images/about/about-9.png"
import Image10 from "../../assets/images/about/about-10.png"
import Image11 from "../../assets/images/about/about-11.jpeg"
import Image12 from "../../assets/images/about/about-12.jpeg"

export default function About() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <NavigationBar toggle={toggle} />
      <WrapperWrapper>
        <Container>
          <Row className="d-flex justify-content-center">
            <BasicH1>
              How Paul Xavier Went From In-Debt Government Contractor To Helping
              Creative Entrepreneurs Earn $42,600,000 From 2018 to Now
            </BasicH1>
          </Row>
        </Container>
        <ContentWrapper>
          <ContentContainer>
            <div>
              <BasicImage src={Image1} alt="Paul pointing to a whiteboard." />

              <BasicH5 className="about__align-with-image-left text-inner-shadow text-inner-shadow">
                My purpose in life & business
              </BasicH5>

              <BasicP>
                My mission in life is to empower creative entrepreneurs with the
                tools, resources, skill developing exercises, and knowledge to
                build wealth doing what they love.
              </BasicP>

              <BasicP>
                Hi I'm Paul Xavier, and on this page I'll be sharing a little
                bit of my story with you but first...{" "}
              </BasicP>

              <BasicP>
                <QuestionIcon />
                <b>Quick question for you:</b>
              </BasicP>

              <BasicP>
                If you were an expert chef, and billionaire Warren Buffett
                offered you financial advice, would you ignore the advice
                because he isn't an expert chef?{" "}
              </BasicP>

              <BasicP>
                ...if you answer: “No - of course I would listen to his advice.
                He is the best at what he does”, I’m extremely excited to share
                the proven Creators Operating System with you.
              </BasicP>

              <BasicP>
                Together we will master your business, grow your income, conquer
                imposter syndrome & reach new levels of freedom in your life &
                business.
              </BasicP>

              <BasicP>
                My goal is not to impress you with my video skills, it’s to
                empower you with the business skills, focus & resources
                necessary to get to the next level in your career as fast & cost
                effectively as possible.
              </BasicP>

              <BasicImage src={Image2} alt="Paul Xavier and Andrew Kovach" />

              <BasicH5 className="about__align-with-image-left text-inner-shadow">
                My 9-5 job with no creative freedom…
              </BasicH5>

              <BasicP>
                Perhaps like you, two months after I graduated from high school
                I got a 9-5 job because I didn’t know what I wanted to do with
                my life…
              </BasicP>

              <BasicP>
                I grew up the son of two loving (divorced) parents: Mark, and
                Sheilah - a little bit of a hippy. <PeaceIcon />
              </BasicP>

              <BasicP>
                My first real job was as a government contractor doing
                recruiting & software development which made me the “SUCCESS” in
                the family because I was making $40,000 a year…
              </BasicP>

              <BasicP>
                That is until four years of my life passed by in what felt like
                a blink & I was desperately craving creative freedom in my life.
              </BasicP>

              <BasicImage src={Image3} alt="Laptops on a desk." />

              <BasicH5 className="about__align-with-image-left text-inner-shadow">
                The tipping point…
              </BasicH5>

              <BasicP>
                Everyday for years at the government, fellow employees would
                talk about how many days they had left until they retired. The
                conversation went like this…
              </BasicP>

              <BasicP>
                <CalendarIcon />
                Tim: “I only have 3567 days left until I’m free! How many days
                are left until you retire?”
              </BasicP>

              <BasicP>
                <CalendarIcon />
                Kelly: “I’m brand new so I still have 6782…”
              </BasicP>

              <BasicP>
                As you can imagine, this repeated conversation drove me to the
                point where I needed a way out…
              </BasicP>

              <BasicP>
                Then when my great friend & first ever business mentor John
                Kovach, who was the CEO of the government contracting company I
                was working at sold the company to retire, it was the final
                straw…
              </BasicP>

              <BasicImage src={Image4} alt="A workstation in the basement." />

              <BasicH5 className="about__align-with-image-left text-inner-shadow">
                Quitting my 9-5 to become a full time creative…
              </BasicH5>

              <BasicP>
                In 2013, I quit my cush 9-5 making $40k a year after getting my
                first couple paying video & website clients with hopes that
                clients would keep on coming in from referrals & networking…
              </BasicP>

              <BasicP>
                <CrossIcon />
                <b>Wrong!</b>
              </BasicP>

              <BasicP>
                It was at that moment that I also became a starving artist
                working project to project, taking any job that someone would
                pay me to do.
              </BasicP>

              <BasicP>
                Working out of this basement... I quickly sunk into a state of
                depression.
                <SadIcon />
              </BasicP>

              <BasicP>
                I was burning myself out working from 7 a.m. to 9 p.m. seven
                days a week but honestly I loved the creative freedom of being
                able to work on projects that I enjoyed.
              </BasicP>

              <BasicP>
                The Big Issue I had was: I had creative freedom but I quickly
                went $12,000 into credit card debt while I was working more
                hours than ever before.
              </BasicP>

              <BasicImage src={Image5} alt="Paul’s family." />

              <BasicH5 className="about__align-with-image-left text-inner-shadow">
                Defining the big three freedoms…
              </BasicH5>

              <BasicP>
                At this low point in my life, John gave me invaluable advice…{" "}
              </BasicP>

              <BasicP>
                <ConversationIcon />
                He said I need to take a moment to stop working IN my business &
                spend some time working ON my business by defining what my goal
                was.
              </BasicP>

              <BasicP>
                At the time I didn’t think the activity would make that big of a
                difference as I was very pessimistic and in a disempowered state
                about everything, but I trusted John and did it anyway.
              </BasicP>

              <BasicP>
                That’s when I created what I call the THREE BIG FREEDOMS:
              </BasicP>

              <BasicP>
                1. Creative Freedom: Having great work relationships & creating
                things that make an impact in the world.
              </BasicP>
              <BasicP>
                {" "}
                2. Time Freedom: The ability to make money while I sleep so that
                I’m not trading hours for dollars.
              </BasicP>
              <BasicP>
                3. Financial Freedom: Having enough money in the bank so that I
                don’t have to worry about finances.
              </BasicP>

              <BasicImage
                src={Image6}
                alt="Business mentors, entrepreneurs, and prizes."
              />

              <BasicH5 className="about__align-with-image-left text-inner-shadow">
                The decision to invest in myself and get help…
              </BasicH5>

              <BasicP>
                <i>
                  “If I have seen further it is by standing on the shoulders of
                  giants.”
                </i>{" "}
                -Isaac Newton
              </BasicP>

              <BasicP>
                Over the next five years I invested $200,000+ in business
                mentors who taught me how to achieve the Three Big Freedoms in
                my company by mastering what I now call the 6 Key Functions of
                any business:
              </BasicP>

              <ol className="about__ol">
                <li>Leadership</li>
                <li>Value Creation</li>
                <li>Marketing</li>
                <li>Sales</li>
                <li>Operations</li>
                <li>Finances</li>
              </ol>
              <BasicP>
                I learned Facebook advertising from people managing over
                $1,000,000 a month in ad spend profitably, went on a retreat to
                Bali with a dozen 6-figure entrepreneurs, went to marketing &
                sales mastery events where I won prizes like this trip on a
                private jet and learned finance strategies from
                multi-millionaires.
              </BasicP>

              <ContentJustifyCenter>
                <BasicImage
                  src={Image7}
                  alt="A factoid from Forbes magazine."
                />
              </ContentJustifyCenter>

              <BasicH5 className="about__align-with-image-left text-inner-shadow">
                Discovering the power of video and distribution…
              </BasicH5>

              <BasicP>
                The two most powerful trends I discovered while working with
                these elite business people were that they all were leveraging
                two powerful forces to grow their companies:
              </BasicP>

              <BasicP>
                <b>1. Video Content</b>
              </BasicP>

              <BasicP>
                Video is the king/queen of communicating value. In business you
                need to provide value otherwise nobody will have any reason to
                pay you. According to Forbes, 90% of customers report that
                product videos help them make purchasing decisions.
              </BasicP>

              <BasicP>
                <b>2. Paid Advertising</b>
              </BasicP>

              <BasicImage src={Image8} alt="Graphs showing trends." />

              <BasicP>
                You see, business is about providing value and one of the
                BIGGEST problems in the world as Mark Cuban so eloquently put it
                is:
              </BasicP>

              <BasicP>
                <i>
                  “Unless you have a reliable, duplicatable, scalable and
                  consistent way to bring potential clients in. You don’t have a
                  business, you have a hobby.”
                </i>
              </BasicP>

              <BasicP>
                Most people have no idea how to create a powerful message using
                video and distribute it to an audience so that they can get
                clients consistently & profitably.
              </BasicP>

              <BasicP>
                And that’s how my video production “agency” was born!
              </BasicP>

              <BasicImage src={Image9} alt="Paul sitting in his house." />

              <BasicH5 className="about__align-with-image-left text-inner-shadow">
                $300k / year offering commercial campaigns…
              </BasicH5>

              <BasicP>
                Instead of being a generalist creative entrepreneur I
                specialized in harnessing the power of video & paid ads to help
                businesses get $2 in clients for every $1 they invested with me.
              </BasicP>

              <BasicP>
                One of my clients, an auto dealership, brought $125,000 in sales
                in the first month after launching their commercial campaign
                where we invested a total of $5,000.
              </BasicP>

              <BasicP>
                The thing that shocks most people is that I’m not a technical
                video expert and for the most part am still improving my video
                quality every day.
              </BasicP>

              <BasicP>
                What I am an expert at is the psychology of a powerful message &
                paid ads which is what has allowed me to get massive results for
                my clients.
              </BasicP>

              <BasicImage src={Image10} alt="Facebook posts." />

              <BasicH5 className="about__align-with-image-left text-inner-shadow">
                Starting to gain attention from fellow creators…
              </BasicH5>

              <BasicP>
                When I started out I would often outsource the camera operating
                to my friends in film school for a few hundred bucks while I
                wrote the video messages & ran the paid ads.
              </BasicP>

              <BasicP>
                They quickly started asking questions on how I was getting video
                campaign clients and I started offering mentoring which built up
                a substantial cash flow for me.
              </BasicP>

              <BasicP>
                So I decided to take the knowledge I had on commercial campaigns
                and teach others how to do the same…
              </BasicP>

              <BasicImage
                src={Image11}
                alt="Paul with his first mentorship clients."
              />

              <BasicH5 className="about__align-with-image-left text-inner-shadow">
                The decision to teach others…
              </BasicH5>

              <BasicP>
                I created an online program called Next Level Creators and began
                showing others how they could offer commercial campaigns and
                build themselves a multiple 6-figure video production agency.
              </BasicP>

              <BasicP>
                As my clients gegan to implement the business strategies & paid
                advertising methods I spent years mastering, I was astonished at
                how many of them began hitting 6 or multiple 6 figures in less
                than 12 months.
              </BasicP>

              <BasicP>
                Three years later we’ve helped 4 people to get 7-figures, and
                50+ people hit 6 or multiple 6-figures, and 100+ people got
                their first $1k-$10k / month commercial campaign client.
              </BasicP>

              <BasicP>
                Building an online program also unlocked a new level of growth
                for my company, catapulting us from $300k to $1,600,000 in the
                next 12 months.
              </BasicP>

              <BasicImage src={Image12} alt="Paul and Miranda in Annapolis." />

              <BasicH5 className="about__align-with-image-left text-inner-shadow">
                Marriage and revolutionizing education…
              </BasicH5>

              <BasicP>
                Now, my goal is to share my knowledge and teach others how to
                create a life that they love.
              </BasicP>

              <BasicP>
                I’m grateful everyday to have my high-school sweetheart as my
                wife with me throughout this journey.
              </BasicP>

              <BasicP>
                She is actually our mindset mentor inside of our community who
                helps our clients overcome limiting beliefs, imposter syndrome,
                and getting out of their comfort zone…
              </BasicP>

              <BasicP>So yeah, she is also a badass. </BasicP>

              <BasicP>
                And even though making a lot of money was never an important
                thing to my wife, I’m incredibly grateful I can provide for all
                of our needs.
              </BasicP>

              <BasicP>
                The other thing I’m eternally grateful for is my first business
                mentor & now operations team leader John Kovach.
              </BasicP>

              <BasicP>
                It was your inspiration that sparked a belief in myself that I
                could do anything and if it wasn’t for your dozens of quotes, I
                would likely still be in that old 9-5 job just scraping by…
              </BasicP>

              <BasicP>My favorite quote by John Kovach:</BasicP>

              <BasicP>
                <i>
                  “Find something you can do that gives you a sense of
                  anticipation when you swing your legs out of bed in the
                  morning and a sense of accomplishment when you swing your legs
                  back into bed at night.”
                </i>
              </BasicP>

              <BasicP>
                To Miranda, my mentors & clients, you have all changed every
                single thing about my life and it’s you that inspire me to work
                harder each day to make your lives as great as you’ve made mine.
              </BasicP>

              <BasicP>
                Seize the day,
                <br />
                &nbsp;&nbsp;&nbsp;- Paul Xavier
              </BasicP>
            </div>
          </ContentContainer>
        </ContentWrapper>
      </WrapperWrapper>
      <CaseStudyCTA />
      <HorizontalLine />
      <SocialFooter />
      <DisclosureFooter />
    </>
  )
}

const WrapperWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: white;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  max-width: 1280px;
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  background: white;
  margin: 30px 0 0 0;
  /* padding-top: 125px; */
  padding-bottom: 50px;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`
const ContentJustifyCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const HorizontalLine = styled.div`
  width: 100vw;
  border-top: 1px solid black;
`
const BasicH1 = styled.h1`
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: clamp(1.8rem, 4vw, 3rem);
  text-align: center;
  font-weight: 900;
  /* width: 80%; */
  color: black;
  /* padding: 20px; */
  margin: 110px 0 0 0;
  /* line-height: 2.2rem; */
`
const BasicH5 = styled.h5`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  margin-top: 25px;
  text-align: center;
`
const BasicP = styled.p`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  margin-top: 20px;
  margin-bottom: 20px;
`
const BasicImage = styled.img`
  max-width: 100%;
  box-shadow: 8px 7px 10px #888890;
`

const QuestionIcon = styled(BsFillQuestionOctagonFill)`
  color: green;
  font-size: 1.3rem;
  margin-bottom: 3px;
  margin-right: 0.3rem;
`
const CalendarIcon = styled(FcCalendar)`
  font-size: 1.3rem;
  margin-bottom: 3px;
  margin-right: 0.3rem;
`
const CrossIcon = styled(GiSkullCrossedBones)`
  font-size: 1.3rem;
  color: red;
  margin-bottom: 3px;
  margin-right: 0.3rem;
`
const SadIcon = styled(ImSad2)`
  font-size: 1.3rem;
  color: #336699;
  margin-bottom: 3px;
  margin-left: 0.3rem;
`
const ConversationIcon = styled(BiConversation)`
  font-size: 1.3rem;
  color: green;
  margin-bottom: 3px;
  margin-right: 0.3rem;
`
const PeaceIcon = styled(FaPeace)`
  font-size: 1.3rem;
  color: purple;
  margin-bottom: 3px;
  margin-left: 0.3rem;
`
